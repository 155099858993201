<template lang="pug">
.mainContainer
  router-view#innerView

</template>

<script setup>
import { computed } from 'vue'
</script>

<style lang="less" scoped>
.mainContainer {
  display: flex;
  height: var(--subAppContainerHeight);
  #innerView {
    background: var(--color-background);
    flex-grow: 1;
    overflow: auto;
  }
}
</style>
